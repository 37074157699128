import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Header from './Header';
import MusicManager from './MusicManager/MusicManager';
import MachineManager from './MachineManager/MachineManager';
import AdsManager from './AdsManager/AdsManager';
import TagManager from './TagManager/TagManager';
import PlaylistManager from './PlaylistManager/PlaylistManager';
import Login from './Login';
import AuthProvider, { AuthContext } from './AuthContext'; // Import both AuthProvider and AuthContext
import DataProvider from './DataContext';

const App = () => {
  return (
    <Router>
      <AuthProvider>
        <AppRoutes />
      </AuthProvider>
    </Router>
  );
};

const AppRoutes = () => {
  const { authData, authLoading } = useContext(AuthContext); // Use AuthContext here

  if (authLoading) {
    return <p>Loading...</p>;
  }

  if (!authData) {
    return (
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<Navigate to="/login" replace />} />
      </Routes>
    );
  }

  return (
    <DataProvider>
      <Header />
      <div className="content">
        <Routes>
          <Route path="/" element={<MusicManager />} />
          <Route path="/tagmanager" element={<TagManager />} />
          <Route path="/playlistmanager" element={<PlaylistManager />} />
          <Route path="/machine-manager" element={<MachineManager />} />
          <Route path="/ads-manager" element={<AdsManager />} />
          <Route path="/login" element={<Navigate to="/" />} />
        </Routes>
      </div>
    </DataProvider>
  );
};

export default App;
