import React, { useContext, useState, useMemo, useEffect } from 'react';
import { DataContext } from '../DataContext';
import './PlaylistManager.css';

const PlaylistManager = () => {
    const { playlists, artists, createPlaylist, updatePlaylist  } = useContext(DataContext); // Access playlists, artists, and createPlaylist
    const [selectedPlaylist, setSelectedPlaylist] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [editName, setEditName] = useState('');
    const [editType, setEditType] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedType, setSelectedType] = useState('All');
    const [loadedSongs, setLoadedSongs] = useState([]);
    const [songSearchQuery, setSongSearchQuery] = useState('');
    const [selectedSongs, setSelectedSongs] = useState([]); // Tracks selected with the "+" button
    const [page, setPage] = useState(1);
    const ITEMS_PER_PAGE = 50;

    // State for creating a playlist
    const [newPlaylistName, setNewPlaylistName] = useState('');
    const [newPlaylistType, setNewPlaylistType] = useState('');

    // Combine all songs from artists
    const allSongs = useMemo(() => {
        return artists.flatMap((artist) =>
            artist.albums.flatMap((album) =>
                album.tracks.map((track) => ({
                    ...track,
                    artistName: artist.name,
                }))
            )
        );
    }, [artists]);

    // Filter playlists based on search query and selected type
    const filteredPlaylists = useMemo(() => {
        if (!playlists) return [];
        return playlists
            .filter(
                (playlist) =>
                    playlist.name.toLowerCase().includes(searchQuery.toLowerCase()) &&
                    (selectedType === 'All' || playlist.playlistType === selectedType)
            )
            .sort((a, b) => a.playlistType.localeCompare(b.playlistType));
    }, [playlists, searchQuery, selectedType]);

    // Filter songs for the third column
    const filteredSongs = useMemo(() => {
        if (!selectedPlaylist) return [];
        return allSongs
            .filter(
                (song) =>
                    !selectedPlaylist.tracks.includes(song.id) &&
                    (song.title.toLowerCase().includes(songSearchQuery.toLowerCase()) ||
                        song.artistName.toLowerCase().includes(songSearchQuery.toLowerCase()))
            )
            .slice(0, page * ITEMS_PER_PAGE);
    }, [allSongs, selectedPlaylist, songSearchQuery, page]);

    // Get CUSTOM_ playlist types for the dropdown
    const customPlaylistTypes = useMemo(() => {
        if (!playlists) return [];
        return [...new Set(playlists.map((playlist) => playlist.playlistType))].filter((type) =>
            type.startsWith('CUSTOM_')
        );
    }, [playlists]);

    // Create a new playlist
    const handleCreatePlaylist = async () => {
        if (!newPlaylistName.trim() || !newPlaylistType) {
            alert('Please enter a name and select a playlist type.');
            return;
        }

        const newPlaylist = {
            name: newPlaylistName.trim(),
            playlistType: newPlaylistType,
            tracks: [],
        };

        try {
            await createPlaylist(newPlaylist); // Call createPlaylist from DataContext
            setNewPlaylistName(''); // Clear name
            setNewPlaylistType(''); // Clear type
        } catch (error) {
            console.error('Failed to create playlist:', error);
        }
    };

    // Enter edit mode
    const handleEditClick = () => {
        setIsEditing(true);
        setEditName(selectedPlaylist.name);
        setEditType(selectedPlaylist.playlistType);
    };

    // Save changes to the playlist
    const handleSaveClick = async () => {
        const updatedPlaylist = {
            ...selectedPlaylist,
            name: editName,
            playlistType: editType,
        };
        try {
            await updatePlaylist(updatedPlaylist); // Update playlist in DataContext
            setSelectedPlaylist(updatedPlaylist); // Update locally
            setIsEditing(false);
        } catch (error) {
            console.error('Failed to save playlist:', error);
        }
    };

    // Cancel editing
    const handleCancelClick = () => {
        setIsEditing(false);
    };

    // Add a song to the intermediate table
    const handleAddSongToIntermediate = (song) => {
        if (!selectedSongs.find((selected) => selected.id === song.id)) {
            setSelectedSongs([...selectedSongs, song]);
        }
    };

    // Remove a song from the playlist in edit mode
 // Remove a song from the playlist in edit mode
    const handleRemoveFromPlaylist = async (trackId) => {
        if (selectedPlaylist) {
            const updatedTracks = selectedPlaylist.tracks.filter((id) => id !== trackId);
            const updatedPlaylist = { ...selectedPlaylist, tracks: updatedTracks };

            try {
                await updatePlaylist(updatedPlaylist); // Update playlist in DataContext
                setSelectedPlaylist(updatedPlaylist); // Update locally
            } catch (error) {
                console.error('Failed to remove song from playlist:', error);
            }
        }
    };

    // Remove a song from the intermediate table
    const handleRemoveFromIntermediate = (songId) => {
        setSelectedSongs((prevSongs) =>
            prevSongs.filter((song) => song.id !== songId)
        );
    };

    // Add selected songs to the playlist
    const handleAddToPlaylist = async () => {
        if (selectedPlaylist) {
            const updatedPlaylist = {
                ...selectedPlaylist,
                tracks: [...selectedPlaylist.tracks, ...selectedSongs.map((song) => song.id)],
            };
            try {
                await updatePlaylist(updatedPlaylist); // Update playlist in DataContext
                setSelectedPlaylist(updatedPlaylist); // Update locally
                setSelectedSongs([]); // Clear the intermediate table
            } catch (error) {
                console.error('Failed to add songs to playlist:', error);
            }
        }
    };

    // Handle scrolling to load more songs dynamically
    const handleScroll = (e) => {
        const bottom =
            e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom && filteredSongs.length < allSongs.length) {
            setPage((prevPage) => prevPage + 1);
        }
    };

    return (
        <div className="playlist-manager">
            <h3>Playlist Manager</h3>

            {/* Filter bar and Create Playlist */}
            <div className="filter-container">
                {/* Search Playlists */}
                <input
                    type="text"
                    placeholder="Search playlists..."
                    className="search-box"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
                <select
                    className="genre-dropdown"
                    value={selectedType}
                    onChange={(e) => setSelectedType(e.target.value)}
                >
                    {['All', ...new Set(playlists.map((playlist) => playlist.playlistType))].map(
                        (type) => (
                            <option key={type} value={type}>
                                {type}
                            </option>
                        )
                    )}
                </select>

                {/* Create Playlist */}
                <div className="create-playlist-container">
                    <label>
                        Name:
                        <input
                            type="text"
                            className="create-playlist-name"
                            value={newPlaylistName}
                            onChange={(e) => setNewPlaylistName(e.target.value)}
                        />
                    </label>
                    <label>
                        Type:
                        <select
                            className="create-playlist-type"
                            value={newPlaylistType}
                            onChange={(e) => setNewPlaylistType(e.target.value)}
                        >
                            <option value="">Select a type</option>
                            {customPlaylistTypes.map((type) => (
                                <option key={type} value={type}>
                                    {type}
                                </option>
                            ))}
                        </select>
                    </label>
                    <button className="create-playlist-button" onClick={handleCreatePlaylist}>
                        Create Playlist
                    </button>
                </div>
            </div>

            <div className="columns">
                {/* Left column - Playlist list */}
                <div className="column">
                    <ul className="playlist-list-content">
                        {filteredPlaylists.map((playlist) => (
                            <li
                                key={playlist.id}
                                className={
                                    selectedPlaylist?.id === playlist.id ? 'selected' : ''
                                }
                                onClick={() => {
                                    setSelectedPlaylist(playlist);
                                    setPage(1); // Reset the page for third column scrolling
                                    setLoadedSongs([]);
                                    setSelectedSongs([]); // Clear intermediate table
                                    setIsEditing(false); // Exit edit mode if switching playlists
                                }}
                            >
                                <span className="playlist-name">{playlist.name}</span>
                                <span className="playlist-type">{playlist.playlistType}</span>
                            </li>
                        ))}
                    </ul>
                </div>

                {/* Middle column - Playlist details */}
                <div className="column">
                    {selectedPlaylist ? (
                        <div className="playlist-details">
                            <div className="playlist-header">
                                <img
                                    src={selectedPlaylist.coverArt?.s3link}
                                    alt={selectedPlaylist.name}
                                    className="playlist-image"
                                />
                                <div className="playlist-info">
                                    {isEditing ? (
                                        <>
                                            <input
                                                type="text"
                                                value={editName}
                                                onChange={(e) => setEditName(e.target.value)}
                                                className="edit-input"
                                            />
                                            <select
                                                value={editType}
                                                onChange={(e) => setEditType(e.target.value)}
                                                className="edit-input"
                                            >
                                                {customPlaylistTypes.map((type) => (
                                                    <option key={type} value={type}>
                                                        {type}
                                                    </option>
                                                ))}
                                            </select>
                                        </>
                                    ) : (
                                        <>
                                            <h4>{selectedPlaylist.name}</h4>
                                            <p>Type: {selectedPlaylist.playlistType}</p>
                                        </>
                                    )}
                                </div>
                                {selectedPlaylist.playlistType.startsWith('CUSTOM_') && (
                                    <div className="edit-buttons">
                                        {isEditing ? (
                                            <>
                                                <button
                                                    className="edit-button"
                                                    onClick={handleSaveClick}
                                                >
                                                    Save
                                                </button>
                                                <button
                                                    className="cancel-button"
                                                    onClick={handleCancelClick}
                                                >
                                                    Cancel
                                                </button>
                                            </>
                                        ) : (
                                            <button
                                                className="edit-button"
                                                onClick={handleEditClick}
                                            >
                                                Edit
                                            </button>
                                        )}
                                    </div>
                                )}
                            </div>

                            <h5>Songs in Playlist</h5>
                            <table className="playlist-table">
                                <thead>
                                    <tr>
                                        <th>Title</th>
                                        <th>Artist</th>
                                        <th>Duration</th>
                                        {isEditing && <th></th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {selectedPlaylist.tracks.map((trackId) => {
                                        const track = allSongs.find((song) => song.id === trackId);
                                        return track ? (
                                            <tr key={track.id}>
                                                <td>{track.title}</td>
                                                <td>{track.artistName}</td>
                                                <td>
                                                    {Math.floor(track.durationMs / 60000)}:
                                                    {((track.durationMs % 60000) / 1000)
                                                        .toFixed(0)
                                                        .padStart(2, '0')}{' '}
                                                    min
                                                </td>
                                                {isEditing && (
                                                    <td>
                                                        <button
                                                            className="remove-button"
                                                            onClick={() =>
                                                                handleRemoveFromPlaylist(track.id)
                                                            }
                                                        >
                                                            -
                                                        </button>
                                                    </td>
                                                )}
                                            </tr>
                                        ) : null;
                                    })}
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        <p>Select a playlist to view details.</p>
                    )}
                </div>

                {/* Right column - Song search and list */}
                <div className="column" onScroll={handleScroll}>
                    {!selectedPlaylist ? (
                        <p>Select a playlist to add songs.</p>
                    ) : selectedPlaylist.playlistType.startsWith('GENERIC') ? (
                        <p>Cannot add songs to GENERIC playlists.</p>
                    ) : (
                        <>
                            {/* Intermediate Table */}
                            {selectedSongs.length > 0 && (
                                <div className="intermediate-table">
                                    <h5>Selected Songs</h5>
                                    <table className="playlist-table">
                                        <thead>
                                            <tr>
                                                <th>Title</th>
                                                <th>Artist</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {selectedSongs.map((song) => (
                                                <tr key={song.id}>
                                                    <td>{song.title}</td>
                                                    <td>{song.artistName}</td>
                                                    <td>
                                                        <button
                                                            className="remove-button"
                                                            onClick={() =>
                                                                handleRemoveFromIntermediate(song.id)
                                                            }
                                                        >
                                                            X
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    <button
                                        className="edit-button"
                                        onClick={handleAddToPlaylist}
                                    >
                                        Add to Current Playlist
                                    </button>
                                </div>
                            )}

                            {/* Song List */}
                            <input
                                type="text"
                                placeholder="Search songs or artists..."
                                className="search-box"
                                value={songSearchQuery}
                                onChange={(e) => setSongSearchQuery(e.target.value)}
                            />
                            <table className="playlist-table">
                                <thead>
                                    <tr>
                                        <th>Title</th>
                                        <th>Artist</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredSongs.map((song) => (
                                        <tr key={song.id}>
                                            <td>{song.title}</td>
                                            <td>{song.artistName}</td>
                                            <td>
                                                <button
                                                    className="add-button"
                                                    onClick={() => handleAddSongToIntermediate(song)}
                                                >
                                                    +
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default PlaylistManager;
