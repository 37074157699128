import React, { useState, useContext, useEffect } from 'react';
import { DataContext } from '../DataContext';
import ArtistList from './ArtistList';
import ArtistEdit from './ArtistEdit';
import AlbumEdit from './AlbumEdit';
import SongEdit from './SongEdit';
import './MusicManager.css';

const MusicManager = () => {
  const { artists, loading, error, tags } = useContext(DataContext);
  const [selectedArtist, setSelectedArtist] = useState(null);
  const [selectedAlbum, setSelectedAlbum] = useState(null);
  const [selectedSong, setSelectedSong] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [genre, setGenre] = useState('');
  const [subgenre, setSubgenre] = useState('');
  const [neverIdle, setNeverIdle] = useState(false);
  const [neverNew, setNeverNew] = useState(false);
  const [onDemand, setOnDemand] = useState(false);
  const [allSongs, setAllSongs] = useState(true);
  const [releaseYear, setReleaseYear] = useState('');
  const [tagSearch, setTagSearch] = useState('');
  const [selectedTags, setSelectedTags] = useState([]); // Store multiple selected tags
  const [showTagDropdown, setShowTagDropdown] = useState(false);


  const allGenres = [...new Set(artists.flatMap((artist) =>
    artist.albums.flatMap((album) =>
      album.tracks.map((track) => track.genre)
    )
  ))].filter(Boolean);

  const allSubgenres = [...new Set(artists.flatMap((artist) =>
    artist.albums.flatMap((album) =>
      album.tracks.map((track) => track.subgenre)
    )
  ))].filter(Boolean);

  // Sync selectedArtist with updates in the artists array
  useEffect(() => {
    if (selectedArtist) {
      const updatedArtist = artists.find((artist) => artist.id === selectedArtist.id);
      if (updatedArtist) {
        setSelectedArtist(updatedArtist);
      }
    }
  }, [artists, selectedArtist]);

  useEffect(() => {
    if (selectedAlbum && selectedArtist) {
      const updatedArtist = artists.find((artist) => artist.id === selectedArtist.id);
      if (updatedArtist) {
        const updatedAlbum = updatedArtist.albums.find((album) => album.id === selectedAlbum.id);
        if (updatedAlbum) {
          setSelectedAlbum(updatedAlbum);
        }
      }
    }
  }, [artists, selectedAlbum, selectedArtist]);

  useEffect(() => {
    if (selectedSong && selectedArtist) {
      const updatedArtist = artists.find((artist) => artist.id === selectedArtist.id);
      if (updatedArtist) {
        // Iterate through all albums to find the one that contains the selected song
        for (const album of updatedArtist.albums) {
          const updatedSong = album.tracks.find((track) => track.id === selectedSong.id);
          if (updatedSong) {
            setSelectedSong(updatedSong);
            break; // Exit the loop once the song is found
          }
        }
      }
    }
  }, [artists, selectedSong, selectedArtist]);

  useEffect(() => {
    if (selectedArtist) {
      setSelectedAlbum(null);
      setSelectedSong(null);
    }
  }, [selectedArtist]);


  const handleSongSelection = (song) => {
    setSelectedSong(song);
    setSelectedAlbum(null); // Deselect album when a song is selected
  };

  const handleAlbumSelection = (album) => {
    setSelectedAlbum(album);
    setSelectedSong(null); // Deselect song when an album is selected
  };

  const clearFilters = () => {
    setSearchQuery('');
    setGenre('');
    setSubgenre('');
    setReleaseYear('');
    setSelectedTags([]); // Clear all selected tags
  };


  if (loading) {
    return (
      <div className="music-manager">
        <h1>Music Manager</h1>
        <p>Loading data, please wait... 🎵</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="music-manager">
        <h1>Music Manager</h1>
        <p>Error loading data: {error}</p>
      </div>
    );
  }

  return (
    <div className="music-manager">
      <h3>Music Manager</h3>

      <div className="filter-container">
        <input
          type="text"
          placeholder="Search artists..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="search-box"
        />

        <div className="filters">
          <span className="filters-title">Filter:</span>
          <input
            type="text"
            placeholder="Filter by genre..."
            value={genre}
            onChange={(e) => setGenre(e.target.value)}
            list="genre-options"
            className="filter-input"
          />
          <datalist id="genre-options">
            {allGenres.map((genreOption) => (
              <option key={genreOption} value={genreOption} />
            ))}
          </datalist>

          <input
            type="text"
            placeholder="Filter by subgenre..."
            value={subgenre}
            onChange={(e) => setSubgenre(e.target.value)}
            list="subgenre-options"
            className="filter-input"
          />
          <datalist id="subgenre-options">
            {allSubgenres.map((subgenreOption) => (
              <option key={subgenreOption} value={subgenreOption} />
            ))}
          </datalist>

          {/* New Release Year Filter */}
          <input
            type="number"
            placeholder="Filter by release year..."
            value={releaseYear || ''}
            onChange={(e) => setReleaseYear(e.target.value)}
            className="filter-input"
          />


          <div className="tag-filter-container">
            {/* Tag Search Dropdown */}
            <div className="tag-dropdown">
              <input
                type="text"
                placeholder="Filter by tag..."
                value={tagSearch}
                onChange={(e) => setTagSearch(e.target.value)}
                onFocus={() => setShowTagDropdown(true)}
                onBlur={() => setShowTagDropdown(false)}
                className="filter-input"
              />
              {showTagDropdown && (
                <div className="dropdown-list">
                  {tags
                    .filter((tag) =>
                      tag.name.toLowerCase().includes(tagSearch.toLowerCase()) &&
                      !selectedTags.some((selectedTag) => selectedTag.id === tag.id) // Exclude already selected tags
                    )
                    .map((tag) => (
                      <div
                        key={tag.id}
                        className="dropdown-item"
                        onMouseDown={() => {
                          setSelectedTags((prevTags) => [...prevTags, tag]); // Add to selected tags
                          setTagSearch(''); // Reset search
                          setShowTagDropdown(false); // Close dropdown
                        }}
                      >
                        {tag.name}
                      </div>
                    ))}
                </div>
              )}
            </div>
            <div className="selected-tags">
              {selectedTags.map((tag) => (
                <span key={tag.id} className="tag-chip">
                  {tag.name}
                  <button
                    className="remove-tag-button"
                    onClick={() => setSelectedTags((prevTags) =>
                      prevTags.filter((t) => t.id !== tag.id)
                    )}
                  >
                    ×
                  </button>
                </span>
              ))}
            </div>
          </div>

          <button className="clear-button" onClick={clearFilters}>
            Clear Filters
          </button>
        </div>

      </div>
      <div className="properties">
        <span className="properties-title">Properties:</span>
        <div className="checkbox-container-group">
          <div className="checkbox-container">
            <input
              type="checkbox"
              checked={neverIdle}
              onChange={(e) => {
                setNeverIdle(e.target.checked);
                if (e.target.checked) {
                  setAllSongs(false); // Uncheck All Songs if any other is checked
                }
              }}
              id="neverIdle"
            />
            <label htmlFor="neverIdle">NeverIdle</label>
          </div>
          <div className="checkbox-container">
            <input
              type="checkbox"
              checked={neverNew}
              onChange={(e) => {
                setNeverNew(e.target.checked);
                if (e.target.checked) {
                  setAllSongs(false); // Uncheck All Songs if any other is checked
                }
              }}
              id="neverNew"
            />
            <label htmlFor="neverNew">NeverNew</label>
          </div>
          <div className="checkbox-container">
            <input
              type="checkbox"
              checked={onDemand}
              onChange={(e) => {
                setOnDemand(e.target.checked);
                if (e.target.checked) {
                  setAllSongs(false); // Uncheck All Songs if any other is checked
                }
              }}
              id="onDemand"
            />
            <label htmlFor="onDemand">OnDemand</label>
          </div>
          <div className="checkbox-container">
            <input
              type="checkbox"
              checked={allSongs}
              onChange={(e) => {
                setAllSongs(e.target.checked);
                if (e.target.checked) {
                  // Uncheck the other three checkboxes when "All Songs" is checked
                  setNeverIdle(false);
                  setNeverNew(false);
                  setOnDemand(false);
                }
              }}
              id="allSongs"
            />
            <label htmlFor="allSongs">All Songs</label>
          </div>
        </div>
        <button className="clear-properties-button" onClick={() => {
          setNeverIdle(false);
          setNeverNew(false);
          setOnDemand(false);
          setAllSongs(true);
        }}>
          Clear Properties
        </button>
      </div>

      <div className="columns">
        <div className="column artist-list">
          <h2>Artists</h2>
          <ArtistList
            artists={artists}
            onSelectArtist={setSelectedArtist}
            selectedArtist={selectedArtist}
            searchQuery={searchQuery}
            genre={genre}
            subgenre={subgenre}
            releaseYear={releaseYear}
            selectedTags = {selectedTags}
            neverIdle={neverIdle}
            neverNew={neverNew}
            onDemand={onDemand}
            allSongs={allSongs}
          />
        </div>
        <div className="column">
          <h2>Artist Editor</h2>
          <ArtistEdit
            artist={selectedArtist}
            onAlbumSelect={handleAlbumSelection}
            onSongSelect={handleSongSelection}
            selectedSong={selectedSong}
            selectedAlbum={selectedAlbum}
          />
        </div>
        <div className="column">
          <h2>Track:</h2>
          {selectedAlbum ? (
            <AlbumEdit selectedArtistId={selectedArtist.id} selectedAlbum={selectedAlbum} />
          ) : selectedSong ? (
            <SongEdit allArtists={artists} selectedArtist={selectedArtist} selectedSong={selectedSong} />
          ) : (
            <p>Please select an album or a song to view details.</p>
          )
          }
        </div>
      </div>
    </div>
  );
};

export default MusicManager;
