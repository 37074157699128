import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import './Header.css';

const Header = () => {
  const { authData } = useContext(AuthContext);

  return (
    <header className="header">
      <nav className="nav">
        <ul className="nav-links">
          <li><Link to="/">Music Manager</Link></li>
          <li><Link to="/tagmanager">Tags</Link></li>
          <li><Link to="/playlistmanager">Playlists</Link></li>
          <li><Link to="/machine-manager">Machines</Link></li>
          <li><Link to="/ads-manager">Ads</Link></li>
        </ul>
        {authData?.accessToken && (
          <div className="user-info">
            <span>Welcome, {authData.userName}!</span>
          </div>
        )}
      </nav>
    </header>
  );
};

export default Header;
